module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mainly Mobiles","short_name":"MMC","start_url":"/","background_color":"white","theme_color":"dimgray","display":"browser","icon":"src/images/mmc.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"79d206b48df5a77d763a770551f6c822"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
